import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Row, Col, Spin } from "antd";
import {} from "@ant-design/icons";

export default function Home({ config }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Spin spinning={loading} tip="Asteptati...">
      <Row>
        <Col span={24}>Welcome</Col>
      </Row>
    </Spin>
  );
}
