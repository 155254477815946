let config = {
  base_url: "http://tzipi.impreunaweb.ro",
  color: {
    primary: "#1890ff",
    info: "#f00",
    success: "#f00",
    warning: "#f00",
    error: "#f00",
    muted: "#ccc",
  },
};

export default config;
