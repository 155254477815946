import { Result, Button } from "antd";

export default function Err500({ message, callback }) {
  return (
    <Result
      status="500"
      title="500"
      subTitle={message ?? "A aparut o eroare..."}
      extra={
        typeof callback === "function" ? (
          <Button
            type="primary"
            onClick={() => {
              callback();
            }}
          >
            Reincearca
          </Button>
        ) : null
      }
    />
  );
}
