import React from "react";
import { Form, Input, Button } from "antd";
import {
  FaRegArrowAltCircleRight,
  FaEnvelope,
  FaRegArrowAltCircleLeft,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Recover({ config }) {
  const color = config.color;
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <p>Introduce-ti adresa de email</p>
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: <small>Adresa de email obligaroie!</small>,
            },
          ]}
        >
          <Input
            placeholder="adresa de email"
            prefix={<FaEnvelope style={{ color: color.primary }} />}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" block>
            Recuperare
            <FaRegArrowAltCircleRight
              style={{ float: "right", marginTop: 4 }}
            />
          </Button>
        </Form.Item>
      </Form>
      <Link to="/login">
        <Button type="link" block>
          Inapoi la conectare
        </Button>
      </Link>
    </>
  );
}
