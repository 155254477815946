import session from "./Session";
import { notification } from "antd";

const API_URL = "http://tzipi.impreunaweb.ro/api";

const Api_FETCH = {
  call: (cmd, post = {}) => {
    return new Promise((resolve, reject) => {
      var key;
      if ((key = session.get("key"))) {
        post["key"] = key;
      }
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(post),
      };
      fetch(`${API_URL}/${cmd}`, options)
        .then((response) => response.json())
        .then((data) => {
          if (typeof data.error !== "undefined") {
            return reject(data.messages.error);
          }
          if (data.notification) {
            notification.open(data.notification);
          }
          return resolve(data);
        })
        .catch((err) => {
          console.log(err);
          return reject("Eroare conexiune server...");
        });
    });
  },
};
const Api_XHR = {
  call: (cmd, info = {}) => {
    return new Promise((resolve, reject) => {
      var key;
      if ((key = session.get(key))) {
        info["key"] = key;
      }
      var xhr = new XMLHttpRequest();
      xhr.open("POST", `${API_URL}/${cmd}`, true);
      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.onload = function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            return resolve(xhr.responseText);
          } else {
            return reject(xhr.statusText);
          }
        }
      };
      xhr.onerror = function (e) {
        return reject(xhr.statusText);
      };
      xhr.send(JSON.stringify(info));
    });
  },
};

export default Api_FETCH;
