import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Error404 from "../screen/error/err404";
import PanelAuth from "../screen/panel/auth";
import PanelAuthLogin from "../screen/panel/auth/Login";
import PanelAuthRecover from "../screen/panel/auth/Recover";
import PanelManager from "../screen/panel/manager";
import PanelManagerHome from "../screen/panel/manager/home";
import PanelManagerParteneri from "../screen/panel/manager/parteneri";
import Front from "../screen/front";
// import FrontHome from "../screen/front/Home";

export default function UrlRoutes({ config }) {
  const params = {
    config: config,
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<PanelAuth {...params} />}>
          <Route index element={<PanelAuthLogin {...params} />} />
          <Route
            path="recover"
            element={<PanelAuthRecover {...params} />}
          ></Route>
        </Route>
        <Route path="/manager" element={<PanelManager {...params} />}>
          <Route index element={<PanelManagerHome {...params} />} />
          <Route
            path="parteneri"
            element={<PanelManagerParteneri {...params} />}
          />
          <Route path="*" element={<Error404 {...params} url="/manager" />} />
        </Route>
        <Route path="/" element={<Front {...params} />}>
          {/* <Route index element={<FrontHome {...params} />} /> */}
        </Route>
        <Route path="*" element={<Error404 {...params} />} />
      </Routes>
    </BrowserRouter>
  );
}
