import React from "react";
import { Layout, Row, Col, Image, Card, Divider } from "antd";
import { useNavigate, Outlet } from "react-router-dom";
// const logo = require("../../../../public/img/logo-imrepunaweb-negru.png");

export default function PanelLayout({ config }) {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (config.user) {
      navigate(`/${config.user.tip.nume}`);
    }
  }, []);
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundImage: `url("/img/bgLogin.png")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Layout.Header
        style={{ backgroundColor: "#fff0", padding: 30, height: "auto" }}
      >
        <Image
          width={200}
          src="/img/logo-imrepunaweb-negru.png"
          preview={false}
        />
      </Layout.Header>
      <Layout.Content>
        <Row align="middle" justify="center">
          <Col xs={24} md={8}>
            <Card>
              <Outlet />
              <Divider></Divider>
              <div style={{ textAlign: "center" }}>
                <small>
                  © {new Date().getFullYear()}{" "}
                  {config.sys.general.licenta.brand}. All rights reserved
                </small>
              </div>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}
