import React from "react";
import { useNavigate, Link, Outlet, useHistory } from "react-router-dom";
import { Layout, Image, Row, Col, Menu } from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const MENU = [
  {
    title: "Bine ai venit",
    url: "",
    icon: <HomeOutlined />,
  },
  {
    title: "Parteneri",
    icon: <TeamOutlined />,
    childs: [
      {
        title: "Clienti",
        url: "/parteneri",
        state: {
          dir: "clienti",
        },
      },
      {
        title: "Furnizori",
        url: "/parteneri",
        state: {
          dir: "furnizori",
        },
      },
    ],
  },
  {
    title: "Documente",
    icon: <FileTextOutlined />,
    childs: [
      {
        title: "Intrari",
        url: "/documente",
        state: {
          dir: "intrari",
        },
      },
      {
        title: "Iesiri",
        url: "/documente",
        state: {
          dir: "iesiri",
        },
      },
      {
        title: "Interne",
        url: "/documente",
        state: {
          dir: "interne",
        },
      },
    ],
  },
];

export default function PanelLayout({ config }) {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  React.useEffect(() => {
    if (!config.user) {
      navigate("/login", { replace: true });
    }
  }, []);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Header style={{ padding: 0 }}>
        <Row>
          <Col flex="200px" style={{ padding: 5 }}>
            <Link to={`/${config.user.tip.nume}`}>
              <Image
                src="/img/logo-imrepunaweb-negru.png"
                preview={false}
                style={{
                  width: "100%",
                  maxHeight: "100%",
                }}
              />
            </Link>
          </Col>
          <Col flex="auto">Header</Col>
        </Row>
      </Layout.Header>
      <Layout>
        <Layout.Sider>
          <Menu
            theme="dark"
            mode="inline"
            openKeys={openMenu}
            onOpenChange={(keys) => {
              setOpenMenu(keys);
            }}
            onSelect={({ item }) => {
              const i = item.props;
              if (typeof i.url !== "undefined") {
                alert("ok");
                navigate(`/${config.user.tip.nume}${i.url}`, {
                  state: i.state,
                });
              }
            }}
          >
            {MENU.map((i, k) =>
              i.childs ? (
                <Menu.SubMenu key={k} title={i.title} icon={i.icon}>
                  {i.childs.map((ii, kk) => (
                    <Menu.Item key={`${k}.${kk}`} url={ii.url} state={ii.state}>
                      {ii.title}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={k} icon={i.icon} url={i.url} state={i.state}>
                  {i.title}
                  {i.url}
                </Menu.Item>
              )
            )}
          </Menu>
        </Layout.Sider>
        <Layout>
          <Layout.Content>
            <Outlet />
          </Layout.Content>
          <Layout.Footer style={{ borderTop: "1px solid #ccc" }}>
            © {new Date().getFullYear()} {config.sys.general.licenta.brand}. All
            rights reserved
          </Layout.Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
