import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function Err404({ message, url }) {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle={message ?? "Ne pare rau, pagina accesata nu exista..."}
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate(url ? url : "/", { replace: true });
          }}
        >
          Back Home
        </Button>
      }
    />
  );
}
