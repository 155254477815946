import React from "react";
import { Form, Input, Button, Spin, Alert } from "antd";
import { FaUserAlt, FaLock, FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import api from "../../../helper/Api";
import session from "../../../helper/Session";
import md5 from "md5";
import { useNavigate } from "react-router-dom";

export default function Login({ config }) {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [users, setUsers] = React.useState(false);
  const color = config.color;
  const onFinish = (values) => {
    setLoading(true);
    setError(false);
    api
      .call("auth/login", {
        username: values.username,
        password: md5(values.password),
      })
      .then((result) => {
        if (result.user) {
          return loginUser(result);
          // } else if (result.users) {
          //   setUsers(result.users);
          // } else {
          //   setError("SYSTEM ERROR");
          //   setLoading(false);
        }

        // session.set("user", result.user);
        // setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const loginUser = (result) => {
    const user = result.user;
    session.set("key", result.sess_id);
    config.user = user;
    navigate(`/${user.tip.nume}`, { replace: true });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Spin spinning={loading} tip="Asteptati...">
      <p>Introduceti datele de conectare</p>
      {error !== false && (
        <Alert
          style={{ marginBottom: 15 }}
          // message={<b>Eroare</b>}
          description={error}
          type="error"
          closable
        />
      )}
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: <small>Numele de utilizator obigatoriu!</small>,
            },
          ]}
        >
          <Input
            placeholder="utilizator"
            prefix={<FaUserAlt style={{ color: color.primary }} />}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: <small>Parola obigatorie!</small> },
          ]}
        >
          <Input.Password
            placeholder="parola"
            prefix={<FaLock style={{ color: color.primary }} />}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" block>
            Conectare
            <FaRegArrowAltCircleRight
              style={{ float: "right", marginTop: 4 }}
            />
          </Button>
        </Form.Item>
      </Form>
      <Link to="/login/recover">
        <Button type="link" block>
          Am uitat parola
        </Button>
      </Link>
    </Spin>
  );
}
