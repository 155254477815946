import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Row, Col, Spin } from "antd";
import {} from "@ant-design/icons";
import api from "../../../helper/Api";
import Err500 from "../../error/err500";

export default function Parteneri({ config }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [filter, setFilter] = React.useState(location.state);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    setLoading(false);
    // api
    //   .call("parteneri/lista", filter)
    //   .then((result) => {
    //     setError(false);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setError({ message: err, callback: init });
    //     setLoading(false);
    //   });
  };

  return error !== false ? (
    <Err500 {...error} />
  ) : (
    <Spin spinning={loading} tip="Asteptati...">
      <Row>
        <Col span={24}>{filter.dir}</Col>
      </Row>
    </Spin>
  );
}
