import React from "react";
import UrlRoutes from "./config/UrlRoutes";
import "antd/dist/antd.css";
import config from "./config/Config";
import session from "./helper/Session";
import api from "./helper/Api";
import { Spin } from "antd";
import Err500 from "./screen/error/err500";

function App() {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [refresh, setRefresh] = React.useState(true);
  React.useEffect(() => {
    init();
  }, []);
  const init = () => {
    setRefresh(true);
    api
      .call(`config/init`)
      .then((res) => {
        session.set("key", res.sess_id);
        config.sys = res.config;
        if (typeof res.user !== "undefined") {
          config.user = res.user;
        }
        setLoading(false);
      })
      .catch((err) => {
        setError({
          message: err,
          callback: init,
        });
        setRefresh(false);
      });
  };
  return loading ? (
    <Spin spinning={refresh} tip="Asteptati...">
      {error !== false ? (
        <Err500 {...error} />
      ) : (
        <div style={{ flex: 1, height: "100vh" }}></div>
      )}
    </Spin>
  ) : (
    <UrlRoutes config={config} />
  );
}

export default App;
